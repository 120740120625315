import { feedbackIntegration, init, replayIntegration } from '@sentry/astro'

const $feedbackIntegration = feedbackIntegration({
  colorScheme: 'light',
  buttonLabel: 'Feedback',
  formTitle: 'Wie gefällt dir unsere neue Beta-Seite?',
  nameLabel: 'Name',
  namePlaceholder: 'Dein Name',
  emailLabel: 'E-Mail-Adresse',
  emailPlaceholder: 'Deine E-Mail-Adresse',
  messageLabel: 'Dein Feedback',
  messagePlaceholder: 'Was gefällt dir, was gefällt dir nicht? Was fehlt dir?',
  submitButtonLabel: 'Feedback abschicken',
  cancelButtonLabel: 'Abbrechen',
  showBranding: false,
  themeLight: {
    submitBackground: '#1D55AF',
    fontFamily: 'Plus Jakarta Sans, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    border: '1px solid #D6D6CC',
    borderRadius: '12px',
    success: '#75A300',
    error: '#DE0A00',
    submitBackgroundHover: '#012240',
    submitForeground: '#ffffff',
    submitForegroundHover: '#ffffff',
    cancelBackground: 'transparent',
    cancelBackgroundHover: 'transparent',
    cancelForeground: '#1D55AF',
    cancelForegroundHover: '#012240',
    inputBackground: '#ffffff',
    inputForeground: '#012240',
    formBorderRadius: '22px',
    formContentBorderRadius: '12px',
  },
})


if (import.meta?.env?.PUBLIC_SENTRY_DSN) {
  init({
    dsn: import.meta.env.PUBLIC_SENTRY_DSN,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
      $feedbackIntegration,
    ],
  })
  $feedbackIntegration.attachTo('[data-sentry="feedback-button"]')
}